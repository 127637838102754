import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CartModule, UserEcommerceModule, EcommerceJadeGroupModule } from 'ecommerce-jade-group-plugin';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DeliveryInfoComponent } from './shared/components/delivery-info/delivery-info.component';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { NgZorroAntdModule, NZ_ICONS } from 'ng-zorro-antd';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/services/interceptor';
registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    DeliveryInfoComponent
  ],
  entryComponents: [
    FooterComponent,
    HeaderComponent,
    DeliveryInfoComponent
  ],
  imports: [
    NgZorroAntdModule,
    EcommerceJadeGroupModule.forRoot({
      BackendUrl: environment.backend,
      RestaurantType: 3,
      RestaurantName: 'Zen Kitchen',
      RestaurantLogoUrl: 'https://i.ibb.co/JCMY81j/logo-zen.jpg',
      appKeyPusher: environment.appKeyPusher,
      hasDelivery: false,
      paymentGateway: 0,
      cardnetCaptureUrl: environment.cardnetCaptureUrl,
      cardnetPublicKey: environment.cardnetPublicKey,
      azulSite: environment.azulSite,
      azulMerchantId: environment.azulMerchantId,
      azulMerchantType: 'MerchantType',
      azulApprovedUrl: '#/menu?Approved=true',
      azulDeclinedUrl: '#/carrito?declined=true',
      azulCancelUrl: '#/carrito?cancel=true',
      dolarRate: 54,
      hasEcommerceActivated: false,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    CartModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: true
    }),
    UserEcommerceModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCKs181Jn6PWOEfsq42Gcuh1LeySnb1n4M',
      libraries: ['places']
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
