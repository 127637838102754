import { Component, OnInit } from '@angular/core';
import { AuthenticationService, CartDataService, EcommerceUserService, EcommerceUtilsService } from 'ecommerce-jade-group-plugin';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public hasEcommerceActivated: boolean = false;
  public userInitial: string;
  constructor(
    public globalService: GlobalService,
    public cartDataService: CartDataService,
    private authenticatioService: AuthenticationService,
    private router: Router,
    private ecommerceUserService: EcommerceUserService,
    private utils: EcommerceUtilsService
  ) {
    this.hasEcommerceActivated = this.utils.hasEcommerceActivated();
    this.ecommerceUserService.getUserFromState()
      .subscribe(initial => this.userInitial = initial);
  }
  GoToAddress() {
    this.router.navigate(['/direcciones']);
  }
  GoToCurrentOrders() {
    if (!this.userInitial) {
      this.authenticatioService.TryAuthorizeLogin(() => {
        this.router.navigate(['/ordenes-pendientes']);
      });
    } else {
      this.router.navigate(['/ordenes-pendientes']);
    }
  }
  OpenCartPreview = (): void => {
    if (!this.userInitial) {
      this.authenticatioService.TryAuthorizeLogin(() => {
        this.globalService.openCartPreview();
      });
    } else {
      this.globalService.openCartPreview();
    }
  }
  openUserProfile = (): void => {
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== '') {
      this.globalService.openUserLoggedPopup();
    } else {
      this.authenticatioService.TryAuthorizeLogin(() => { });
    }
  }
  ngOnInit() {
  }

  public optionClicked = (): void => {
    $('.collapse').collapse("hide");
  }

}
