import { Component } from '@angular/core';
import { GlobalService } from './shared/services/global.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MessagingService, IdentifierService } from 'ecommerce-jade-group-plugin';

declare var gtag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showPopupCartPreview: boolean = false;
  public showPopupUserLogin: boolean = false;
  public showPopupUserLogged: boolean = false;
  public showInvisibleDiv: boolean = false;

  ngOnInit() {
    this.identifier.GetIdentifier().then((deviceuuid) => {
      this.messagingService.requestPermission(deviceuuid);
      this.messagingService.receiveMessage();
    });
  }
  constructor(
    private globalService: GlobalService,
    public identifier: IdentifierService,
    private router: Router,
    private messagingService: MessagingService
  ) {
    this.globalService.onShowInvisibleDiv.subscribe(value => this.showInvisibleDiv = value);
    this.globalService.onToggleVisibilityCartPreviewPopup.subscribe((value) => this.showPopupCartPreview = value);
    this.globalService.onToggleVisibilityUserLoginPopup.subscribe((value) => this.showPopupUserLogin = value);
    this.globalService.onToggleVisibilityUserLoggedPopup.subscribe((value) => this.showPopupUserLogged = value);

    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      // gtag('config', 'UA-146862685-1', {
      //   'page_path': event.urlAfterRedirects
      // });
    });
  }
  UserLoggedIn() {
    this.globalService.onToggleVisibilityUserLoginPopup.next(false);
    this.globalService.onClickInvisibleDiv();
  }
  UserLoggedOut() {
    this.globalService.onToggleVisibilityUserLoggedPopup.next(false);
    this.globalService.onClickInvisibleDiv();
    this.router.navigate(['/menu']);
  }
  closeUserToLogin() {
    this.globalService.onClickInvisibleDiv();
  }
  GoToMyCurrentOrders() {
    this.globalService.onClickInvisibleDiv();
    this.router.navigate(['/ordenes-pendientes']);
  }
  onClickInvisibleDiv() {
    this.globalService.onClickInvisibleDiv();
  }
  GoToCartPage() {
    this.globalService.onToggleVisibilityCartPreviewPopup.next(false);
    this.router.navigate(['/carrito']);
    this.globalService.onClickInvisibleDiv();
  }
  public goToMenu = () => {
    this.router.navigate(['/menu']);
    this.globalService.onClickInvisibleDiv();
  }

  public goToRegister = () => {
    this.router.navigate(['/registro']);
    this.globalService.onClickInvisibleDiv();
  }

  public goToRecoverPassword = () => {
    this.router.navigate(['/recuperar-contraseña']);
    this.globalService.onClickInvisibleDiv();
  }

  public goToProfile = () => {
    this.router.navigate(['/perfil/0']);
    this.globalService.onClickInvisibleDiv();
  }
}
