import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalService } from './services/global.service';
import { MessagingService } from 'ecommerce-jade-group-plugin';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    GlobalService,
    MessagingService
  ]
})
export class SharedModule { }