import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const cardnetScript = document.createElement("script");
cardnetScript.type = "text/javascript";
cardnetScript.src = `${environment.cardnetUrl}?key=${environment.cardnetPublicKeyAnonymous}`;
document.body.append(cardnetScript);

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
